import React from "react";
import FadeIn from "react-fade-in";

export default function Loader() {
  return (
    <div className="flex items-center justify-center h-screen bg-red-500">
      <FadeIn>
        <p className="text-white -mt-10 text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold uppercase tracking-wide	">
          <code
            class="typeWriter home"
            data-checkVisible="true"
            data-speed="4"
            data-text='["Notre vie moins chère"]'
          ></code>
        </p>
      </FadeIn>
    </div>
  );
}
