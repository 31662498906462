import React from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();

  const NavLink = ({ text, icon, link }) => (
    <a
      onClick={(e) => {
        e.preventDefault();
        navigate(link);
      }}
      href="#"
      className="hover:underline px-2 rounded-full flex flex-row items-center"
    >
      {/* <i className={icon}></i> */}
      <span className="">{text}</span>
    </a>
  );

  const NavIcon = ({ link, icon }) => (
    <a href={link} target="_blank" className="mx-3">
      <i className={icon}></i>
    </a>
  );

  return (
    <footer className="text-xs w-full bg-black text-white p-5">
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <span className="">
          Copyright&copy; 2023 Notre vie moins chère | Tous les droits sont
          réservés{" "}
        </span>
        <div className="mt-5 md:mt-0 flex items-center">
          <NavLink link={"/"} text="Accueil" icon={"bi bi-whatsapp"} />
          <NavLink
            link={"/contact"}
            text="Nous contacter"
            icon={"bi bi-whatsapp"}
          />
          <NavLink
            link={"/terms"}
            text="Conditions d'utilisation"
            icon={"bi bi-whatsapp"}
          />
        </div>
      </div>
    </footer>
  );
}
