import React, { useEffect, useState } from "react";
import {
  signInWithPopup,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import axios from "axios";
import moment from "moment";
import { auth, db } from "../config/firebase";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Login() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(true);
  const [checked, setChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginMode, setIsLoginMode] = useState(true);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (auth.currentUser) {
  //     navigate("/");
  //   }
  // }, []);

  const login = async () => {
    setIsLoading(true);
    await signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        toast.success("Vous êtes connecté●e", {
          position: toast.POSITION.TOP_RIGHT,
        });
        navigate("/");
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.code == "auth/user-not-found") {
          setIsLoading(false);
          toast.error(
            "Nous n'avons pas trouver de compte lié à cette adresse email",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          return;
        }

        setIsLoading(false);
        toast.error("Une erreur est survenue, veuillez réessayer", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const addToMailChimp = async () => {
    const API_KEY = "a35234b973ec3dbcefe618121b19aea0-us13";
    const AUDIENCE_ID = "7490a78a41";
    const LIST_URL = `https://us13.api.mailchimp.com/3.0/lists/${AUDIENCE_ID}/members/`;

    try {
      const response = await axios.post(
        LIST_URL,
        {
          email_address: email,
          merge_fields: {
            FNAME: firstName,
            LNAME: lastName,
          },
          status: "subscribed",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `apikey ${API_KEY}`,
          },
        }
      );
    } catch (error) {}
  };

  const register = async () => {
    setIsLoading(true);
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (res) => {
        const myRef = doc(db, "users", res.user.uid);
        setDoc(
          myRef,
          {
            firstName: firstName,
            lastName: lastName,
            email: email,
            verified: false,
            createdAt: moment().format(),
          },
          { merge: true }
        );
        updateProfile(auth.currentUser, {
          displayName: firstName + " " + lastName,
        });

        if (checked) {
          addToMailChimp();
        }
        navigate("home");
      })
      .catch(async (error) => {
        switch (error.code) {
          case "auth/invalid-email":
            toast.error("L'adresse Email est invalide", {
              position: toast.POSITION.TOP_RIGHT,
            });
            break;
          case "auth/operation-not-allowed":
            toast.error("Opération non autorisée", {
              position: toast.POSITION.TOP_RIGHT,
            });
            break;
          case "auth/weak-password":
            toast.error("Mot de passe trop faible", {
              position: toast.POSITION.TOP_RIGHT,
            });
            break;
          default:
            toast.error("Une erreur est survenue", {
              position: toast.POSITION.TOP_RIGHT,
            });
            break;
        }
      });
    setIsLoading(false);
  };
  return (
    <div className="bgg min-h-screen bg-gray-200 flex items-center justify-center">
      <form className="w-11/12 md:w-4/12 mx-auto bg-white rounded-xl p-5">
        <button
          onClick={(e) => {
            e.preventDefault()
            navigate(-1)}}
          className="self-end text-xs hover:underline text-gray-400"
        >
          <i class="bi bi-chevron-left mr-2"></i>
          <span>Revenir à l'accueil</span>
        </button>
        <h1 className="mt-10 text-3xl font-bold text-red-500">
          {isLoginMode ? "Connexion" : "Inscription"}
        </h1>
        <p className="text-sm text-gray-500 mb-10 ">
          {isLoginMode
            ? "Connectez-vous à votre compte"
            : "Inscrivez-vous dès maintenant pour profiter de nos offres"}
        </p>

        <div className="flex flex-col">
          {!isLoginMode && (
            <>
              <input
                className="text-sm py-3 pl-5 pr-2 rounded bg-gray-100"
                name="last_name"
                type="text"
                placeholder="Nom"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
              <input
                className="text-sm py-3 pl-5 pr-2 rounded bg-gray-100 my-3"
                name="first_name"
                type="text"
                placeholder="Prénom"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </>
          )}
          <input
            className="text-sm py-3 pl-5 pr-2 rounded bg-gray-100"
            name="email"
            type="e-mail"
            placeholder="E-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="text-sm py-3 pl-5 pr-2 rounded bg-gray-100 mt-3"
            name="password"
            type="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {isLoginMode && (
            <a className="mt-3 self-end text-xs hover:underline text-gray-400">
              Mot de passe oublié
            </a>
          )}

          <button
            onClick={(e) => {
              e.preventDefault();
              if (isLoginMode) {
                login();
              } else {
                register();
              }
            }}
            className="py-3 px-5 text-sm text-white bg-red-500 hover:bg-red-600 rounded mt-10"
          >
            {isLoginMode ? "Se connecter" : "S'inscrire"}
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
              setIsLoginMode(!isLoginMode);
            }}
            className="mt-3 text-sm text-center font-light hover:underline text-black"
          >
            Ou je {isLoginMode ? "m'inscris" : "me connecte"} en cliquant ici
          </button>
        </div>
      </form>
    </div>
  );
}
