import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  collection,
  getDocs,
  limit,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import "../styles/App.scss";
import Modal from "react-modal";
import Page from "../layouts/Page";
import { auth, db } from "../config/firebase";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";


export default function Home() {
  const TIMING = 10;
  const [timing, setTiming] = useState(TIMING);

  const [data, setData] = useState([]);
  const [courses, setCourses] = useState([]);
  const [banner, setBanner] = useState();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCat, setSelectedCat] = useState("all");

  const [showCode, setShowCode] = useState(false);
  const [modalCode, setModalCode] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });


  const customStyles = {
    content: {
      width: isMobile ? "90%" : "40%",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };


  useEffect(() => {
    getCategories();
    getCodes("all");
    getPartners();
    getBanner();
  }, []);

  function goToHome(e) {
    window.location.href = "#";
    e.preventDefault();
    e.stopPropagation();
  }

  function goToProjects(e) {
    window.location.href = "#projects";
    e.preventDefault();
    e.stopPropagation();
  }

  async function getCodes(cat) {
    setIsLoading(true);
    const q =
      !cat || cat == "" || cat == "all"
        ? query(collection(db, "codes"))
        : query(
            collection(db, "codes"),
            limit(4),
            where("category", "==", cat.toLowerCase())
          );
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach(async (res) => {
      dataArr.push({
        id: res.id,
        ...res.data(),
      });
    });
    setData(dataArr);
    // setTimeout(() => {
    // }, 800);
    setIsLoading(false);
  }

  async function getCategories() {
    const q = query(collection(db, "categories"));
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach(async (res) => {
      dataArr.push({
        id: res.id,
        ...res.data(),
      });
    });
    dataArr.push({ id: "all", name: "all" });
    setCategories(dataArr);
  }

  async function getPartners() {
    const q = query(collection(db, "partner"));
    const querySnapshot = await getDocs(q);
    const dataArr = [];
    querySnapshot.forEach(async (res) => {
      dataArr.push({
        id: res.id,
        ...res.data(),
      });

      setCourses(dataArr);
    });
    setIsLoading(false);
  }

  async function getBanner() {
    const docRef = doc(db, "banner", "banner");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setBanner(docSnap.data());
    }
  }

  const openModal = (item) => {
    if (!auth.currentUser) {
      toast.error(
        "Vous devez être connecté●e pous pouvoir voir le code et en profiter",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }
    setModalCode(item.code);
    setIsVisible(true);
  };

  const showTheCode = (e) => {
    e.preventDefault();
    setShowCode(true);

    const interval = setInterval(() => {
      setTiming((timing) => timing - 1);
    }, 1000);

    setTimeout(() => {
      setShowCode(false);
    }, TIMING * 1000);

    setTimeout(() => {
      clearInterval(interval);
      setTiming(TIMING);
    }, TIMING * 1100);

    return () => {
      clearInterval(interval);
    };
  };

  const CodeCard = ({ item }) => (
    <div className="flex flex-col items-center w-5/6 md:w-[30vw]">
      <img
        className="self-center h-2/6 w-4/6 rounded-sm bg-white border-gray-500"
        src={item?.imgUrl}
      />
      <div className="mt-3 p-3 bg-gray-100 w-full rounded-sm flex flex-col text-sm">
        <span className="font-semibold text-gray-500 text-xs uppercase">
          {item?.category}
        </span>
        <div className="flex flex-row items-center mb-1">
          <h3 className="font-bold text-black text-md mr-3 capitalize">
            {item?.name}
          </h3>
          <div className="bg-red-500 px-3 py-1 rounded-full">
            <span className="text-white">-{item?.percent}%</span>
          </div>
        </div>
        <p className="font-light text-gray-700 text-xs">
          {item?.desc.length > 100 ? item?.desc.substr(0, 100) : item?.desc}
        </p>
        <button
          onClick={() => openModal(item)}
          className="self-start bg-black p-2 rounded-xl mt-5 w-1/3"
        >
          <span className="text-white text-xs text-center">Profiter</span>
        </button>
      </div>
    </div>
  );

  const CourseCard = ({ id, name, desc, link, imgUrl, special }) => (
    <div className="w-5/6 md:w-[40%] mb-7 rounded-xl bg-white flex flex-row items-center">
      {special ? (
        <img
          className="self-center h-1/2 w-3/12 rounded-md bg-white  border-gray-500 mr-3"
          src={imgUrl}
          resizeMode="contain"
        />
      ) : (
        <img
          className="self-center h-1/2 w-3/12 rounded-md bg-white  border-gray-500 mr-3"
          src={imgUrl}
          resizeMode="contain"
        />
      )}

      <div className="w-9/12 p-5 bg-gray-50">
        <div className="flex-row items-center mb-3">
          <h4 className="text-lg text-black mr-3 capitalize font-bold">
            {name}
          </h4>
        </div>
        <p className="text-xs text-gray-800 mb-5">
          {desc.length > 100 ? desc.substr(0, 100) : desc}
        </p>
        <a
          target="_blank"
          href={link}
          className="self-start bg-black p-2 rounded-xl"
        >
          <span className="text-white text-xs text-center">Découvrir</span>
        </a>
      </div>
    </div>
  );

  if (isLoading) return null;

  return (
    <Page
      content={
        <div>
          <section className="bgg min-h-screen text-white">
            <div className="py-10 md:max-w-7xl mx-auto flex flex-col md:flex-row items-center">
              <div className="w-10/12 md:w-1/2">
                <h1 className="uppercase font-bold text-xl md:text-4xl">
                  Découvrez l'Art de l'Économie : Visualisez, Économisez,
                  Prospérez !
                </h1>
                <p className="text-gray-200 text-sm md:text-md mt-5 md:mt-10 mb-2 md:mb-5">
                  Plongez dans un monde de remises exclusives en visionnant des
                  publicités captivantes. N'attendez plus pour faire prospérer
                  votre portefeuille. Cliquez maintenant et découvrez l'art de
                  l'économie !
                </p>
                <div className="flex items-center">
                  <a className="w-1/2 md:w-3/12 p-2">
                    <img
                      className="w-full"
                      src={require("../img/appStore.png")}
                    />
                  </a>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.nvmc"
                    className="w-1/2 md:w-3/12"
                  >
                    <img
                      className="w-full"
                      src={require("../img/playStore.png")}
                    />
                  </a>
                </div>
              </div>
              <div className="mt-5 md:mt-0 w-1/2 flex items-center justify-end">
                <img src={require("../img/bg.png")} />
              </div>
            </div>
          </section>

          <section className="mt-10">
            <div className="max-w-7xl mx-auto">
              <h2 className="text-lg md:text-2xl px-2 font-semibold">
                Nos offres actuelles
              </h2>
              <div className="mt-5 flex flex-row overflow-scroll text-xs truncate">
                {categories.map((item, i) => (
                  <button
                    key={i}
                    onClick={() => {
                      setSelectedCat(item.name);
                      getCodes(item.name);
                    }}
                    className={
                      selectedCat == item.name
                        ? "mx-2 bg-blue-200 py-2 px-4 rounded-full"
                        : "mx-2 bg-gray-200 py-2 px-4 rounded-full"
                    }
                  >
                    <span className="text-sm capitalize">{item.name}</span>
                  </button>
                ))}
              </div>
              <div className="mt-10 flex items-center justify-center flex-wrap gap-3 ">
                {data.map((item, i) => (
                  <CodeCard key={i} item={item} />
                ))}
              </div>
            </div>
          </section>

          <section className="mt-20 py-10 bg-gray-100">
            <div className="max-w-7xl mx-auto">
              <h2 className="text-lg md:text-2xl px-2 font-semibold">
                Mes courses gratuites
              </h2>

              <div className="mt-10 flex items-center justify-center flex-wrap gap-5">
                {courses.map((item, i) => (
                  <CourseCard
                    key={i}
                    id={item.id}
                    name={item.name}
                    desc={item.desc}
                    link={item.link}
                    imgUrl={item.imgUrl}
                  />
                ))}
              </div>
            </div>
          </section>

          <Modal isOpen={isVisible} style={customStyles}>
            <button
              className="bg-black text-white p-2 w-7 h-7 rounded flex items-center justify-center absolute right-5 top-5"
              onClick={() => setIsVisible(false)}
            >
              X
            </button>
            <div className="bg-white h-[60vh] w-full p-7 rounded-xl flex-col items-center justify-between">
              <div className="w-full">
                <h1 className="text-lg mb-2 font-bold">Voir le code</h1>
                {showCode ? (
                  <p className="text-xs font-light">
                    Le code disparaitra dans {timing}s
                  </p>
                ) : (
                  <p className="text-xs">
                    Vous avez 10 secondes pour pouvoir profiter du code
                  </p>
                )}
                <div className="mt-7 bg-gray-100 rounded-md p-5 items-center justify-center">
                  <p className="text-2xl text-green-500">
                    {showCode ? modalCode : "_ _ _ _ _ _"}
                  </p>
                </div>
              </div>

              {timing == TIMING && (
                <button
                  onClick={showTheCode}
                  className="bg-black px-3 py-2 rounded-xl mt-5 w-5/6"
                >
                  <span className="text-white text-sm text-center">Voir</span>
                </button>
              )}
            </div>
          </Modal>
        </div>
      }
    />
  );
}
